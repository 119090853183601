* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  max-width: 900px;
  margin: 0px auto;
  height: 100vh;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
}

.form {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  width: 200px;
}

input,
button {
  width: 100%;
  height: 30px;
  margin: 5px;
}

.messages {
  width: 100%;
  height: 300px;
  overflow-y: auto;
  border: 1px solid black;
  padding: 10px;
}

.msg-wrap {
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.msg-me {
  border: 1px solid gray;
  border-radius: 3px;
  display: inline;
  padding: 2px;
  margin-right: auto;
}
.msg-alien {
  border: 1px solid gray;
  border-radius: 3px;
  display: inline;
  padding: 2px;
  margin-left: auto;
}

.nick {
  font-size: 12px;
  color: blue;
}

.avatar {
  width: 20px;
  height: 20px;
}

.avatar img {
  width: 100%;
  border-radius: 50%;
}
.nick-wrap-me {
  display: flex;
  margin-right: auto;
}
.nick-wrap-alien {
  display: flex;
  margin-left: auto;
}

.btn {
  max-width: 60px;
}
